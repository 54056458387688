<template>
  <section id="daftar-surat-kegiatan">
    <b-card>
      <!-- search input -->
      <div
        v-if="userData.group_id === 100"
        class="d-flex justify-content-end mb-2"
      >
        <b-link
          :to="{ name: 'pengajuan-surat-baru' }"
          class="btn btn-outline-success btn-sm"
        >
          Tambah Surat Permohonan
        </b-link>
      </div>

      <!-- search input -->
      <b-row class="justify-content-end mb-1">
        <b-col md="4">
          <b-input-group>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
            />
            <b-input-group-append>
              <b-button
                v-if="searchTerm"
                variant="outline-secondary"
                class="p-50"
                @click="searchReset"
              >
                <feather-icon
                  icon="XCircleIcon"
                  size="12"
                />
              </b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button
                variant="outline-secondary"
                @click="tableSearch"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="12"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        mode="remote"
        :total-rows="totalRecords"
        :is-loading.sync="isLoading"
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          perPage: serverParams.length
        }"
        :group-options="{
          enabled: true
        }"
        style-class="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
      >
        <template
          slot="table-header-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'nama_pengadaan'">
            <b-link
              v-b-tooltip.hover
              :to="{ name: 'pengajuan-surat-detail', params: { id: props.row.clearance_id } }"
              class="item-review text-primary text-wrap"
              title="Daftar Pengadaan"
            >
              {{ props.row.label }}
            </b-link>
          </span>
          <span v-else-if="yearBudget === 2023 && props.column.field === 'nama_kegiatan'">
            <b-link
              v-b-tooltip.hover
              :to="{ name: 'pengajuan-surat-detail', params: { id: props.row.clearance_id } }"
              class="item-review text-primary text-wrap"
              title="Daftar Pengadaan"
            >
              {{ props.row.label }}
            </b-link>
          </span>
          <span v-else-if="props.column.field === 'status'">
            {{ Number(props.row.children.length).toLocaleString() }} /
            {{ Number(props.row.total_program).toLocaleString() }} Kegiatan
          </span>

          <span v-if="props.column.field == 'action'">
            <b-button
              v-if="yearBudget === 2023 && props.row.children.length < props.row.total_program && userData.group_id !== 10"
              v-b-tooltip.hover
              :to="{ name: 'kegiatan-baru', params: { id: props.row.clearance_id } }"
              variant="info"
              size="sm"
              class="text-wrap mr-25 mb-25"
              style="max-width:76px"
              title="Tambah Kegiatan"
            >
              Tambah Kegiatan
            </b-button>
            <b-button
              v-if="yearBudget === 2025 && props.row.children.length < props.row.total_program && userData.group_id !== 10"
              v-b-tooltip.hover
              variant="info"
              size="sm"
              class="text-wrap mr-25 mb-25"
              style="max-width:76px"
              title="Tambah Kegiatan"
              @click.prevent="tambahKegiatan(props.row.clearance_id)"
            >
              Tambah Kegiatan
            </b-button>
            <b-button
              v-if="userData.group_id === 100 && props.row.finished === false"
              v-b-tooltip.hover
              :to="{ name: 'pengajuan-surat-ubah', params: { id: props.row.clearance_id } }"
              variant="outline-secondary"
              size="sm"
              title="Ubah Data Surat"
            >
              <feather-icon
                icon="EditIcon"
                size="14"
              />
            </b-button>
          </span>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field === 'nama_kegiatan'"
            class="text-wrap"
          >
            <b-link
              v-b-tooltip.hover
              :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
              class="item-review text-secondary"
              title="Detail Kegiatan"
            >
              {{ props.row.nama_kegiatan }}
            </b-link>
          </span>
          <span v-else-if="props.column.field === 'total_anggaran'">
            {{ Number(props.row.total_anggaran).toLocaleString() }}
          </span>
          <span v-else-if="props.column.field === 'last_update'">
            {{ props.row.last_update | formatDate }}
          </span>
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              :variant="statusVariant(props.row.status).class"
              class="text-wrap"
              style="max-width:120px"
            >
              {{ statusVariant(props.row.status).title }}
            </b-badge>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field == 'action'">
            <b-button
              v-if="props.row.status === 'Perlu Perbaikan'"
              v-b-tooltip.hover
              :to="{ name: 'kegiatan-review', params: { id: props.row.data_utama_id } }"
              variant="warning"
              size="sm"
              class="mr-25 mb-25"
              title="Review Kegiatan"
            >
              <feather-icon
                icon="FileTextIcon"
                size="14"
              />
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover
              :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
              variant="primary"
              size="sm"
              class="mr-25 mb-25"
              title="Detail Kegiatan"
            >
              <feather-icon
                icon="FileTextIcon"
                size="14"
              />
            </b-button>
            <b-button
              v-if="yearBudget === 2023 && (props.row.status === 'Draft' || props.row.status === 'Perlu Perbaikan')"
              v-b-tooltip.hover
              :to="{ name: 'kegiatan-ubah', params: { id: props.row.data_utama_id } }"
              variant="secondary"
              size="sm"
              class="mr-25 mb-25"
              title="Ubah / Lengkapi Kegiatan"
            >
              <feather-icon
                icon="EditIcon"
                size="14"
              />
            </b-button>
            <b-button
              v-if="yearBudget !== 2023 && (props.row.status === 'Draft' || props.row.status === 'Perlu Perbaikan')"
              v-b-tooltip.hover
              :to="{ name: 'kegiatan-ubah-item', params: { id: props.row.data_utama_id } }"
              variant="secondary"
              size="sm"
              class="mr-25 mb-25"
              title="Ubah / Lengkapi Kegiatan"
            >
              <feather-icon
                icon="EditIcon"
                size="14"
              />
            </b-button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="serverParams.length"
                :options="['15', '30', '60', '100']"
                class="mx-1"
                @change="(value) => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-model="selectPage"
                :total-rows="props.total"
                :per-page="serverParams.length"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

    <b-modal
      id="modal-tambah-kegiatan"
      ref="modal-tambah-kegiatan"
      title="Pilih Kriteria Belanja"
      hide-footer
      centered
      scrollable
      size="md"
    >
      <b-card-text>
        <template v-if="yearBudget < 2025">
          <b-list-group>
            <b-list-group-item
              v-for="data in referenceData.ref_jenis_pengadaan"
              :key="data.jenis_pengadaan_id"
              class="d-flex justify-content-start align-items-center"
              :to="{ name: 'kegiatan-baru-item', params : {id: clearance_id, jenis: data.jenis_pengadaan_id} }"
            >
              Belanja {{ data.nama }}
            </b-list-group-item>
          </b-list-group>
        </template>

        <template v-else>
          <h5>{{ stepAnggaran }}</h5>
          <b-list-group>
            <b-list-group-item
              v-for="data in referenceData.ref_jenis_pengadaan"
              :key="data.jenis_pengadaan_id"
              class="d-flex justify-content-start align-items-center"
              :to="{ name: 'kegiatan-baru-krisna', params : {id: clearance_id, jenis: data.jenis_pengadaan_id} }"
            >
              Belanja {{ data.nama }}
            </b-list-group-item>
          </b-list-group>
        </template>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Data Kegiatan?
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BBadge, BLink, BCard, BButton, BPagination, BFormInput, BListGroup, BListGroupItem,
  BFormSelect, VBTooltip, VBModal, BModal, BCardText, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/ippdColumns'

export default {
  components: {
    BCardText,
    BLink,
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    VueGoodTable,
    BBadge,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormSelect,
    BListGroup,
    BListGroupItem,
  },
  setup() {
    const { statusColors } = stColors()
    const { oldColumns, newColumns } = tableColumns()

    const yearBudget = Number(localStorage.getItem('tahunAnggaran'))
    const columns = (yearBudget === 2023) ? oldColumns : newColumns

    return {
      yearBudget,
      statusColors,
      columns,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      stepAnggaran: 'Krisna',
      userData: getUserData(),
      isLoading: false,
      totalRecords: 0,
      selectPage: 1,
      yfilter: {
        column: 'tahun_anggaran',
        value: localStorage.getItem('tahunAnggaran'),
      },
      serverParams: {
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        sort_by: [
          {
            column: 'last_update',
            asc_desc: 'desc',
          },
        ],
        start: 0,
        length: 30,
      },
      rows: [],
      searchTerm: '',
      toDelData: '',
      toDelItem: '',
      clearance_id: '',
      referenceData: {
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_pic: [],
        ref_sumber_dana: [],
      },
      krisna: false,
    }
  },
  mounted() {
    if (process.env.VUE_APP_STEP === 'sakti') {
      this.stepAnggaran = 'Sakti'
    }
    this.updateParams({
      filters: [this.yfilter],
    })
    this.loadItems()
    this.getReference()
  },
  methods: {
    tableSearch() {
      this.updateParams({ keyword: this.searchTerm })
      this.loadItems()
    },
    searchReset() {
      this.searchTerm = ''
      this.updateParams({ keyword: '' })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.selectPage = params.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ length: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      params.map(param => {
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: param.field,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      const filterz = [this.yfilter]
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry
        filterz.push({
          column: key,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      if (this.userData.group_id === 10) {
        this.serverParams.instansi_id = this.userData.instansi_id
      }
      this.$http.post(`/clearance/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            res.data.data.data.map(value => {
              const pic = value
              pic.finished = false
              if (pic.children.length !== 0) {
                pic.children.map(child => {
                  if (child.status_id === 80) {
                    pic.finished = true
                  }
                  return true
                })
              }
              this.rows.push(pic)
              return true
            })
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
        })
    },
    statusVariant(status) {
      if (this.statusColors[status]) {
        return this.statusColors[status]
      }
      return {
        title: status,
        class: 'light-success',
      }
    },
    removeKegiatan(value, index) {
      this.toDelData = value
      this.toDelItem = index
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
      this.toDelItem = ''
    },
    deleteItem() {
      this.$http.delete(`/clearances/programs/${this.toDelData}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(() => {
          this.rows.splice(this.toDelItem, 1)
          this.clearDeleteItem()
        })
    },
    tambahKegiatan(val) {
      this.clearance_id = val
      this.$refs['modal-tambah-kegiatan'].show()
    },
    getReference() {
      this.$http.get('/clearance/core-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: this.yearBudget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
          }
        })
    },
  },
}
</script>
